import React from "react";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const Failure = () => {
  //   const navigate = useNavigate();
  return (
    <div className="container">
      <div className="h-screen w-full flex justify-center items-center">
        <div className="col-md-6 text-center">
          <div className="bg-red-400 py-3 mb-3 rounded-md text-center">
            <h4 className="alert-heading text-white">
              Oops, something went wrong!
            </h4>
          </div>
          <Link to="/" className="hover:underline">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Failure;
