import React from "react";

export default function BlogModal({ children, onClose, onOpen }) {
  return (
    <>
      {onOpen ? (
        <>
          <div className="justify-center items-center hide-scrollbar overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative max-w-[700px] my-6 mx-auto px-5">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none pb-5">
                {/*header*/}
                <div className="flex items-start justify-between pt-2 px-7">
                  <button
                    className="p-1 ml-auto bg-transparent text-lg border-0 text-black float-right leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => onClose()}
                  >
                    x
                  </button>
                </div>
                {/*body*/}
                <div className="relative px-6 flex-auto">{children}</div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
