import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserById } from "../Api";

const Success = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    getUser();
  }, []);
  const getUser = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
  };
  const navigation = useNavigate();
  
  const updateUserProfile = async () => {
    try {
      const res = await getUserById(user?.userId);
      const userData = {
        ...JSON.parse(localStorage.getItem("user")),
        firstName: res?.data?.user?.firstName,
        lastName: res?.data?.user?.lastName,
        email: res?.data?.user?.email,
        phoneNumber: res?.data?.user?.phoneNumber,
        filename: res?.data?.user?.filename,
        endDate: res?.data?.user.endDate,
        active: res?.data?.user.active,
        subscription: res?.data?.user.subscription,
      };
      if (res?.status) {
        localStorage.setItem("user", JSON.stringify(userData));
        navigation(`/`);
        window.location.reload();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  return (
    <div className="container">
      <div className="h-screen w-full flex justify-center items-center">
        <div className="col-md-6 text-center">
          <div className="bg-green-600 py-3 mb-3 rounded-md text-center">
            <h4 className=" text-white">Payment Successfull</h4>
          </div>
          <button
            onClick={() => updateUserProfile()}
            className="hover:underline"
          >
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
