// import Header from "./Header";
// import Footer from "./Footer";
import "../App.css";
import profile from "../assests/profile-dummy.jpg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFacebook,
//   faInstagram,
//   faXTwitter,
// } from "@fortawesome/free-brands-svg-icons";
// import Navbar from "./Navbar";

const BlogDetail = ({ blog }) => {
  return (
    <>
      <div className="blog-container w-full bg-gray-200">
        <div className="section w-full text-sm bg-white">
          <div className="px-4 author flex justify-between align-middle items-center w-full">
            {/* <div className="flex gap-4 author-img">
              <div className="object-cover border-black rounded-full max-w-10 max-h-10">
                <img
                  src="https://picsum.photos/120"
                  alt=""
                  className="rounded-full"
                />
              </div>
              <h2 className="mt-3 font-bold">Admin</h2>
            </div> */}

            {/* <div className="read mt-0 bg-slate-300 w-max p-2 rounded-md">
              <p className="text-slate-700">05:34 min to read</p>
            </div> */}
          </div>

          <div className="p-2 mt-6 article">
            {/* <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laborum
              molestias fugiat nisi cumque soluta ex saepe ab officia,
              recusandae molestiae nihil voluptatibus rem quisquam impedit porro
              repellat provident voluptatum praesentium.
            </p> */}
            <div className="mt-3 h-40 flex justify-center article-img object-cover rounded-md">
              {/* <img
                src="https://picsum.photos/1600/400"
                alt="some-img"
                className="object-cover rounded-md"
              /> */}
              <img
                // srcSet="https://picsum.photos/800/400 800w, https://picsum.photos/400/200 400w"
                //   sizes="(max-width: 600px) 400px, 800px"
                src={
                  blog?.filename
                    ? `${process.env.REACT_APP_IMAGE_BASE_BLOG_URL}/${blog?.filename}`
                    : profile
                }
                // srcSet="https://picsum.photos/800/400 800w, https://picsum.photos/400/200 400w"
                sizes="(max-width: 600px) 400px, 800px"
                className="h-full w-full rounded-md"
                alt="blog-img"
              />
            </div>
            <p className="mt-2 p-2">{blog?.description}</p>
            {/* <p className="mt-1 p-2">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet
              nobis accusamus eaque velit saepe aut molestias, incidunt minus
              aliquid natus totam amet nulla tempore asperiores quae quidem
              laboriosam perspiciatis. Dolorem?
            </p> */}
            {/* <h1 className="mt-2 p-2 font-bold text-2xl">
              This Is a Sample Heading
            </h1> */}
            {/* <p className="p-2">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet
              nobis accusamus eaque velit saepe aut molestias, incidunt minus
              aliquid natus totam amet nulla tempore asperiores quae quidem
              laboriosam perspiciatis. Dolorem?
            </p> */}
            {/* <ul className="p-3 list-disc list-inside custom-bullet-color">
              <li>This is item #1</li>
              <li>This is item #2</li>
              <li>This is item #3</li>
            </ul> */}
            {/* <div className="quote mt-2 p-3 bg-green-800 rounded-md">
              <p className="p-5 text-white font-bold text-center">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Provident perspiciatis, unde, mollitia nobis reiciendis
                quibusdam. Lorem ipsum dolor sit, amet consectetur adipisicing
                elit. Quo sint, enim suscipit dolor, quos voluptatum corporis
                consectetur.
              </p>
            </div> */}
            {/* <p className="mt-3 p-1">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet
              nobis accusamus eaque velit saepe aut molestias, incidunt minus
              aliquid natus totam amet.
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
