import React, { createContext, useContext, useEffect, useState } from "react";
import { getUserById, loginUser } from "../Api";
import { useNavigate } from "react-router-dom";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const navigation = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // const updateUser = (newUserData) => {
  //   setUser(newUserData);
  //   localStorage.setItem("user", JSON.stringify(newUserData));
  // };

  // This effect can be used if you're using a subscription or polling
  useEffect(() => {
    // Your subscription or polling mechanism
    // Whenever there's an update, call `updateUser` with the new data
    // For demonstration purposes, I'll just use a timeout
    const timer = setTimeout(() => {
      updateUserProfile();
    }, 5000); // Every 5 seconds for demonstration

    return () => clearTimeout(timer); // Clean up subscription
    // eslint-disable-next-line
  }, []); // Empty dependency array means it runs only once on mount

  const updateUserProfile = async () => {
    if (user) {
      try {
        const res = await getUserById(user?.userId);
        const userData = {
          ...JSON.parse(localStorage.getItem("user")),
          firstName: res?.data?.user?.firstName,
          lastName: res?.data?.user?.lastName,
          email: res?.data?.user?.email,
          phoneNumber: res?.data?.user?.phoneNumber,
          filename: res?.data?.user?.filename,
          endDate: res?.data?.user.endDate,
          active: res?.data?.user.active,
          subscription: res?.data?.user.subscription,
        };
        if (res?.status) {
          localStorage.setItem("user", JSON.stringify(userData));
        }
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  const login = async (values) => {
    try {
      const res = await loginUser(values);
      if (res?.data?.success) {
        localStorage.setItem("user", JSON.stringify(res?.data));
        setUser({ isAuth: true, ...res?.data });

        if (res?.data?.role === "Admin") {
          navigation("/blog");
        } else if (res?.data?.active && res?.data?.subscription) {
          navigation(`/doc/${res?.data?.userId}`);
        } else {
          navigation(`/pricing`);
        }
      }
    } catch (error) {
      navigation("/signup");
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
  };
  return (
    <UserContext.Provider
      value={{ user, setUser, isAuthenticated, login, logout }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
