import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
// import { hosted } from "../Api";
const public_stripe_key = process.env.REACT_APP_PUBLIC_STRIPE_KEY;

const PricingSection = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
  };
  const [toggleState, setToggleState] = useState({
    price: 10,
    duration: "month",
    name: "basic",
    desc: "monthly subscription",
  });

  const handleSubscription = async () => {
    const stripePromise = await loadStripe(public_stripe_key);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL_2}/create-stripe-session-subscription/${user?.userId}`,
      {
        method: "POST",
        headers: { "Content-Type": "Application/JSON" },
        body: JSON.stringify(toggleState),
        mode: "cors",
      }
    );

    if (response.status === 409) {
      const data = await response.json();
      if (data && data.redirectUrl) {
        window.location.href = data.redirectUrl; // redirect to billing portal if user is already subscribed
      }
    } else {
      const session = await response.json();
      stripePromise.redirectToCheckout({
        sessionId: session.id,
      });
    }
  };

  return (
    <div className="pricing_section">
      <div className="heading_pricing2">Simple Pricing</div>
      <div className="radio_section">
        <button
          className={toggleState.price === 10 ? "statebtn1" : "statebtn"}
          onClick={() => {
            setToggleState({
              ...toggleState,
              price: 10,
              duration: "month",
            });
          }}
        >
          Monthly
        </button>
        <button
          className={toggleState.price === 100 ? "statebtn1" : "statebtn"}
          onClick={() => {
            setToggleState({
              ...toggleState,
              price: 100,
              duration: "year",
              name: "premium",
              desc: "yearly subscription",
            });
          }}
        >
          Yearly
        </button>
      </div>
      <p className="pricing_content">
        We can't explain ourselves like our customers and their numbers can.
      </p>
      <div className="crd_flex">
        <div className="crd1">
          <div className="crd_title">
            {toggleState?.price === 10 ? "Free" : "Premium"}
          </div>
          <div className="content_5">
            {toggleState?.price === 10
              ? " The Free Plan is perfect for those who are just starting their journey with Reawaken. It provides essential features to help you reorganize and rejuvenate your tasks. With the Free Plan, you can:"
              : "Our Premium Plan offers a comprehensive suite of features designed to unleash your full potential. With the Premium Plan, you'll enjoy  all the benefits of the Free Plan, plus::"}
          </div>
          <div className="title_4">Starts at</div>
          <div className="title_1">
            {toggleState.price === 10 ? "$10" : "$100"}
          </div>
          <div className="title_4">yearly</div>
          <div className="child_flex">
            <img className="tik" src="/images/icon.svg" alt="" />
            <div>Inspiration-Driven Workspaces</div>
          </div>
          <div className="child_flex">
            <img className="tik" src="/images/icon.svg" alt="" />
            <div>Advanced Visualizations</div>
          </div>
          <div className="child_flex">
            <img className="tik" src="/images/icon.svg" alt="" />
            <div>Enhanced Collaboration Tools</div>
          </div>
          <div className="child_flex">
            <img className="tik" src="/images/icon.svg" alt="" />
            <div>Unlimited Workspaces</div>
          </div>
          <div className="child_flex">
            <img className="tik" src="/images/icon.svg" alt="" />
            <div>Detailed Progress Tracking</div>
          </div>
          <div className="child_flex">
            <img className="tik" src="/images/icon.svg" alt="" />
            <div>Priority Customer Support</div>
          </div>
          <button onClick={(e) => handleSubscription(e)} className="get_btn">
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;

// const Message = ({ message }) => (
//   <section>
//     <p>{message}</p>
//   </section>
// );
