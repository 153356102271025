import React from "react";
import PricingSection from "../Components/PricingSection";

const Pricing = () => {
  return (
    <div className="bg_next">
      <PricingSection />
    </div>
  );
};

export default Pricing;
