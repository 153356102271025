import { useState } from "react";
// import Modal from "./Modal";
// import Blogpage from "./Blogpage";
// import { Link } from "react-router-dom";
import BlogDetail from "./BlogDetail";
import BlogModal from "./BlogModal";
import profile from "../assests/profile-dummy.jpg";

const BlogCard = ({ blog }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleViewClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="Blog border flex flex-col justify-between m-4 border-solid rounded-lg shadow-md overflow-hidden bg-white h-auto">
      <div>
        <div className="h-40 w-full overflow-hidden">
          <img
            // srcSet="https://picsum.photos/800/400 800w, https://picsum.photos/400/200 400w"
            //   sizes="(max-width: 600px) 400px, 800px"
            src={
              blog?.filename
                ? `${process.env.REACT_APP_IMAGE_BASE_BLOG_URL}/${blog?.filename}`
                : profile
            }
            className="h-full w-full object-fill"
            alt="blog-img"
          />
        </div>

        <h2
          style={{ textDecorationLine: "none !important" }}
          className="m-3 lg:text-2xl text-base no-underline font-bold"
        >
          {blog?.title}
        </h2>

        <p className="m-3 lg:text-base text-sm line-clamp-6 text-gray-800">
          {blog?.description}
        </p>
      </div>

      <div className="justify-self-end">
        <div className="blog-info mt-5  flex justify-between m-4">
          <div className="author items-center flex">
            <div className="object-cover rounded-full max-w-10 max-h-10">
              <img
                src="https://picsum.photos/120"
                alt="author"
                className="object-cover rounded-full"
              />
            </div>
            <div className="mt-1 ml-4 text-xs name-prof flex flex-col">
              <h2 className="font-bold">Admin</h2>
              <p>Technical Engineer</p>
            </div>
          </div>
          {/*<Link to={`/blog/${blog.id}`}>*/}
          {/* Link with blog ID */}
          <button
            className="text-white rounded-md bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4"
            onClick={handleViewClick}
          >
            View
          </button>
          {isModalOpen && (
            <BlogModal onClose={handleModalClose} onOpen={isModalOpen}>
              <BlogDetail blog={blog} />
            </BlogModal>
          )}
          {/*</Link>*/}
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
