import React from "react";
import Accordion from "./Accordian";
import InviteNewUser from "./InviteNewUser";

const SomeMoreStuff = () => {
  return (
    <div className="some">
      <Accordion />
      <InviteNewUser />
    </div>
  );
};

export default SomeMoreStuff;
