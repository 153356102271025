import React from "react";
import Invite from "../assests/image.svg";

const InviteNewUser = () => {
  return (
    <div className="invite">
      <img src={Invite} alt="Invite" />
    </div>
  );
};

export default InviteNewUser;
