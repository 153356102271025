import React, { useEffect, useState } from "react";
import BlogCard from "../Components/BlogCard";
import Loader from "../Components/Loader";
import { getAllBlogs } from "../Api";
import Search from "../Components/Search";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

export default function BlogPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getAllBlog();
    // eslint-disable-next-line
  }, [currentPage, searchQuery]);

  const getAllBlog = async () => {
    try {
      const res = await getAllBlogs(currentPage, searchQuery);
      if (res?.data?.success) {
        setData(res?.data?.paginatedPosts);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="bg-gray-200">
      <div className="w-full">
        <Navbar />
      </div>
      <div className="xl:px-20 lg:px-5 py-10 flex flex-wrap items-center justify-center blogs">
        <div className="w-full">
          <Search handleSearchChange={handleSearchChange} />
        </div>
        {loading ? (
          <div className="w-full h-screen flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="grid w-full xl:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-5 md:gap-1">
              {data?.map((blog) => (
                <BlogCard key={blog._id} blog={blog} />
              ))}
            </div>
          </>
        )}
      </div>
      <div className="m-2 page-nav  py-10 flex justify-center">
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          className="m-1 h-10 w-14 bg-gray-500 p-1 rounded-md text-white hover:bg-gray-700 transition"
        >
          Prev
        </button>

        {/* <button className="m-1 h-10 w-10 bg-blue-600 p-1 rounded-md text-white hover:bg-gray-700 transition">
          1
        </button> */}

        <button
          onClick={nextPage}
          className="m-1 h-10 w-14 bg-gray-500 p-1 rounded-md text-white hover:bg-gray-700 transition"
        >
          Next
        </button>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}
